<template>
	<div class="material-card">	
		<div class="container bg-white card-lite">
		    <div class="row no-gutters">
		        <div class="col">            
		            <h3 class="heading-text" >SAVED FOOD CARTS</h3>                    		            
		        </div>
		    </div>
		    <div v-if="savedOrdersLoading" class="flex-row dead-center" style="padding-bottom:20px;">
		    	<beat-loader></beat-loader>
		    </div>
		    <template v-else>	
			    <div class="row align-items-center" v-if="!user" style="padding-bottom: 15px;">
			    	<div class="col-2">
				    	<img src="./../../assets/key.png" width="65px" alt="">			    		
			    	</div>
			    	<div class="col-10">	
				    	<h3><router-link class="text-primary" to="/sign-in">Login</router-link> to access your saved orders</h3>
			    	</div>
			    </div>
			    <div v-else-if="Object.keys(savedOrders).length == 0" style="padding-bottom: 15px;">
			            You haven't saved any meals. You can save one the next time you checkout and order faster!
			    </div>
			    <div v-else class="flex-row space-around center bg-white text-white" style="padding-top: 15px;padding-bottom: 15px;">
			        <div v-if="savedOrders['breakfast']" class="flex-column space-around align-items-center" style="flex-basis: 30%;height: 120px;background: linear-gradient(#2980b9,#3498db);padding-top: 10px;">
			        <div>
			          <strong>Breakfast</strong>
			          <div style="height: 3px;background-color: white;width: 15px;margin-top: 10px;"></div>
			        </div>
			        <button @click="orderFromSavedMeal('breakfast')" class="btn-wide bg-white material-shadow-1" style="width: 80%;">ORDER</button>
			        </div>
			        <div v-if="savedOrders['lunch']" class="flex-column space-around align-items-center" style="flex-basis: 30%;height: 120px;background: linear-gradient(#00A651,#2ecc71) ;padding-top: 10px;">
			            <div>
			              <strong>Lunch</strong>
			              <div style="height: 3px;background-color: white;width: 15px;margin-top: 10px;"></div>
			            </div>
			        <button @click="orderFromSavedMeal('lunch')" class="btn-wide bg-white material-shadow-1" style="width: 80%;">ORDER</button>
			        </div>
			        <div v-if="savedOrders['dinner']" class="flex-column space-around align-items-center" style="flex-basis: 30%;height: 120px;background: linear-gradient(#d35400,#e67e22);padding-top: 10px;">
			            <div>
			              <strong>Dinner</strong>
			              <div style="height: 3px;background-color: white;width: 15px;margin-top: 10px;"></div>
			            </div>
			        <button @click="orderFromSavedMeal('dinner')" class="btn-wide bg-white material-shadow-1" style="width: 80%;">ORDER</button>
			        </div>                          
			    </div>
			</template>
		</div>
	</div>
</template>


<script>
	import {db} from './../../database'
	import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
	import cartMixin from './../../cartMixin'

	export default {
		props:['cart', 'user'],
		data(){
			return {
				savedOrders: {},
				savedOrdersLoading: true	
			}
		},
		created(){
		    if(this.user) {
		        db.doc("users/"+this.user.uid).onSnapshot(doc => {
		        	this.savedOrders = {}
		        	this.savedOrdersLoading = false
		            if(doc.exists) {
		                if(doc.data().savedOrders) {
		                    this.savedOrders = doc.data().savedOrders                    
		                }                        
		            }               
		        })
		    } else {
		        this.savedOrdersLoading = false		    	
		    } 
		},		
		methods: {
		    orderFromSavedMeal(mealType){
				this.emptyCart()
				var items = this.savedOrders[mealType]
				this.addSavedItemsToCart(items).then(()=>{
					this.$router.push('/cart')
				})		    
			},
			addSavedItemsToCart(items) {
				this.savedOrdersLoading = true
				const thisRef = this
				return new Promise((resolve,reject) => {
					items.forEach((item,index) => {
						let currentItem = {}
						db.collection("products").where("id", "==", item.id).get().then(data => {
							data.forEach(item => currentItem = item.data())

							if(currentItem.variant) {
								let itemVarients = currentItem.variant.type
								let itemVarientTypeIndex = itemVarients.indexOf(item.variant)
								currentItem['weight'] = item.variant
								currentItem['qty'] = item.qty							
								currentItem['price'] = this.getCurrentItemPrice(currentItem,itemVarientTypeIndex,item.veg)  
								delete currentItem['variant']
							}

							thisRef.cart.push(currentItem)
							if(index == (items.length-1)) { resolve() }
						})
					})					
				})			
			},
			// getSavedItems(items){
			// 	return new Promise((resolve,reject) => {
			// 		var currentItems = []
			// 		items.forEach((item,index) => {
			// 			db.collection("products").where("id", "==", item.id).get().then(data => {
			// 				data.forEach(item => { currentItems.push(item.data()) })							
			// 				if(index == (items.length-1)) { resolve() }
			// 			})
			// 		})					
			// 	})				
			// }
		},
		components: {
			'beat-loader': BeatLoader,
		},
		mixins: [cartMixin]		
	}
</script>
