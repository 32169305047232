<template>
  <div>
    <flickity ref="flickity" :options="flickityOptions">
      <div v-for="(card,index) in cards" :key="card.id" class="carousel-cell" :style="{ color: card.color }">
        <div class="carousel-card text-center" :class="`card-${index}`" :style="{ backgroundColor: card.background }">
          <div class="carousel-card-tag">
            {{ card.tag }}
          </div>   
          <div class="card-img-container dead-center" :style="{ backgroundColor: card.accent }" style="display:flex;border-radius:50%;">            
            <img :src="card.image" alt="">          
          </div>       
          <h2>{{ card.title }}</h2>
        </div>
      </div>      
    </flickity>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
export default {
  components: {
    Flickity,
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        autoPlay:false,
        wrapAround: false,
        contain: true
        // any options from Flickity can be used
      },
      cards: [
        {
          id: 'card-1',
          title: "GET DISCOUNTS ON ALL ORDERS",
          background: "#f3a683",
          accent: "#f19066",
          image: "https://www.iconpacks.net/icons/2/free-discount-icon-2045-thumb.png",
          tag: "SPECIAL OFFER",
          color: '#2c3e50',
        },
        // {
        //   id: 'card-4',
        //   title: "FREE DELIVERY FOR LIMITED TIME",
        //   background: "#63cdda",
        //   accent: "#3dc1d3",
        //   image: require('@/assets/free-shipping.png'),
        //   tag: "INTRODUCTORY OFFER",
        //   color: "#2c3e50"
        // },        
        {
          id: 'card-3',
          title: "CUSTOM MADE CAKES FOR YOU",
          background: "#f7d794",
          accent: "#f5cd79",
          image: require('@/assets/custom-cake.png'),
          tag: "BY CHERRYFROST",
          color: "#2c3e50"
        },
        {
          id: 'card-2',
          title: "SPECIAL OUT OF THE BOX CAKES",
          // background: "#ea8685",
          background: "#63cdda",
          // accent: "#e66767",
          accent: "#3dc1d3",
          image: require('@/assets/ootb-cake.png'),
          tag: "BY CHERRYFROST",
          color: "#2c3e50"
        }
      ]
    };
  },
};
</script>


<style scoped>
.carousel {
  background: #EEE;  
}

.carousel-cell{
    height: 290px;
    /* background: rebeccapurple; */    
    width: 400px;
    margin-right: 15px;
    border-radius: 4px;    
}

.carousel-card {
  height: 100%;
  padding:10px;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
}

.carousel-card h2 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.3em;
}

.carousel-card img {
  height: 75px;  
}

.card-img-container {
  height:120px;
  width:120px;
}

/* .carousel-cell:first-child {
  width: 300px;
} */

.carousel-card-tag {
  margin-top: 15px;
  font-size:14px;
  letter-spacing:2px;
  color:white;
  margin-bottom:15px;
  background:#303952;
  display:inline-block;
  padding:6px 12px;
}

@media(max-width: 768px){
  .carousel-cell{
    height: 200px;
    width: 50%;
  }

  .carousel-card h2 {
    font-size: 14px;
    font-weight: 600;
  }

  .carousel-card img {
    height: 50px;  
  }

  /* .carousel-card:first-child{
    margin-left: 20px;
  } */

  /* .card-0 {
    margin-left: 10px;
  } */

  .card-img-container {
    height:85px;
    width:85px;
  }

  .carousel-card-tag {
    margin-top:5px;
    font-size:10px;
    letter-spacing:1px;                
    padding:4px 10px;
  }

}
</style>