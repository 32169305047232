<template>
	<div>
		<div class="container">
			<div class="row no-gutters center-spaced">
				<p style="flex-basis:80%;">
					<strong class="text-success"><i class="icon-location"></i></strong>&nbsp;
					{{ getUserLocation }}, {{ getUserCity }} 					
				</p>
				<small style="flex-basis:20%;" @click="goHome()" class="text-primary cursor-pointer text-right" role="button">Change</small>
			</div>
		</div>
	    <div class="container bg-white">
	        <div class="row no-gutters">
	            <div class="col">            
		            <h3 class="heading-text" >BROWSE A STORE</h3>                    	                
	            </div>
	        </div>
	    </div>
	    <div class="container center card-lite" style="padding-top: 30px;padding-bottom: 30px;background:url('https://i.imgur.com/dD7NTUv.jpg');background-position: center;background-size: cover;background-repeat: no-repeat;">
	        <div class="flex-row space-between">
	            <div class="bg-white material-shadow-1" style="flex-basis: 49%;border-radius: 8px;padding-left: 2%;padding-right: 2%;">
	                <div style="margin-bottom: 15px;padding-top: 20px;" class="center">
						<div class="text-white bg-dark delay-1s animated shake" style="letter-spacing:1px;position:relative;height:20px;margin-bottom:-20px;bottom:30px;">
							<small>MINIMUM 15% OFF</small>
						</div>	                	
	                    <img src="./../../assets/rice.png" width="70px;" style="margin-bottom: 15px;">
	                    <h2 style="font-family:Pompiere;letter-spacing: 2px;font-weight: lighter;margin-top: 0px;margin-bottom: 5px;" >
	                        Create Yor Own Special Meal
	                    </h2>
	                  <div style="width:30px;height:3px;display:inline-block;margin-bottom:8px;" class="bg-primary"></div>                          
	                </div>
	                    <router-link to="/menu/Eats" class="btn-wide bg-primary text-white center" tag="button" style="width: 80%;margin-bottom: 20px;">OPEN EATS</router-link>
	            </div>
	            <div class="bg-white material-shadow-1" style="border-left: 1px solid #e2e2e0;flex-basis: 49%;border-radius: 8px;padding-left: 2%;padding-right: 2%;">
	                
					<div style="margin-bottom: 15px;padding-top: 20px;" class="center">
						<div class="text-white bg-dark delay-2s animated shake" style="letter-spacing:1px;position:relative;height:20px;margin-bottom:-20px;bottom:30px;">
							<small>MINIMUM 25% OFF</small>
						</div>
	                    <img src="./../../assets/birthday-cake.png" width="70px;" style="margin-bottom: 15px;" >
	                      <h2 style="font-family:Pompiere;letter-spacing: 2px;font-weight: lighter;margin-top: 0px;margin-bottom: 5px;" >
	                          Cakes For Every Occassion
	                      </h2>
	                    <div style="width:30px;height:3px;display:inline-block;margin-bottom:8px;" class="bg-success"></div>
	                </div>
	                    <router-link to="/menu/Bakery" class="btn-wide bg-success text-white center" tag="button" style="width: 80%;margin-bottom: 20px;">OPEN BAKERY</router-link>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	// This mixin supplies the chosen user location
	import AddressValidationMixin from './../../mixins/AddressValidationMixin'
	import {bus} from './../../main'

	export default {
		methods: {
			goHome(){
			  bus.$emit('goHome')
			},
		},
		mixins: [AddressValidationMixin]
	}
</script>
