<template>
<div class="bg-default">
    <select-store></select-store>
    <saved-orders v-bind:user="user" v-bind:cart="cart"></saved-orders>
    <categories-recommended v-if="enableRecommendations" v-bind:cart="cart"></categories-recommended>
    <app-footer></app-footer>
</div>
</template>

<script>

import {bus} from './../main'
import {db} from './../database'    
import SelectStore from './parts/SelectStore'    
import SavedOrders from './parts/SavedOrders'    
import CategoriesRecommended from './parts/CategoriesRecommended'    
import Footer from './Footer'

// MIXINS
import EnterAndLeaveRouteMixin from './../mixins/EnterAndLeaveRouteMixin'

export default {
    props:['user', 'cart'],        
    data(){
        return {
            enableRecommendations: false
        }
    },
    mounted(){
        this.hideNav()
        this.hidePreloader()
    },
    components: {
        'select-store': SelectStore,
        'saved-orders': SavedOrders,
        'categories-recommended': CategoriesRecommended,
        'app-footer': Footer
    },
    mixins: [EnterAndLeaveRouteMixin]
}
</script>