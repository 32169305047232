<template>
	<div class="margin-top-15">
		<div v-if="currentTimeInHours < 21">
			<div class="container bg-white material-card card" style="padding-bottom: 10px;">
				<div class="row no-gutters">
					<div class="col-12 center">
						<strong class="text-primary">|</strong> &nbsp; <small>ALL CAKES ARE FRESH AND EGGLESS  &nbsp; <strong class="text-primary">|</strong> </small>                                
					</div>
					<div class="col-12">
						<h3 class="lead-text">Select a Flavour</h3>
						<select name="" id="" v-model="selectedFlavour">
							<option :value="cake.flavour" :key="cake.id" v-for="cake in cakes">
								{{ cake.flavour }}
							</option>
						</select>
						<p class="text-right">
							<strong class="text-success">*Price for {{ selectedWeight }} {{ selectedFlavour }} Cake: </strong> &#8377;{{ getItemPrice() }} &nbsp;<small><del>&#8377;{{ getItemNonDiscountedPrice(getItemPrice(), getItemDiscount()) }}</del></small>
						</p>
						<h3 class="lead-text">Select Weight</h3>
						<select v-model="selectedWeight">
							<option :key="weight" :value="weight" v-for="weight in weights">{{ weight }}</option>
						</select>
					</div>
					<div class="col-12">
						<button @click="addItemToCart()" class="btn-wide btn-primary">ADD CAKE TO CART</button>
					</div>
				</div>
			</div>
			<div class="container bg-white material-card" style="border-top: 3px solid #00A651;">
				<div class="row no-gutters">
					<div class="col-12">
						<h3 class="heading-text">HOW IT WORKS</h3>
						<p>
							Select the weight and flavour for your cake, we will provide you with a beautifully designed fresh cake for your selection within an hour!
						</p>
						<p>
							<small class="strong"> Have any queries? Call 7578025236</small>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="container">
				<div class="row no-gutters">
					<div class="col-12">
						<h3>Sorry we are closed for today :( <br>Come back tomorrow!</h3>						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {bus} from './../main'
	import {db} from './../database'
	import cartMixin from './../cartMixin'	

	export default {
		props: ['cart'],
		data(){
			return {
				flavours: ['Vanilla', 'Black Forest', 'White Forest', 'Butterscotch', 'Chocolate', 'Pineapple'],
				weights: ['0.5 Kg', '1 Kg'],
				selectedFlavour: 'Vanilla',
				selectedWeight: '0.5 Kg',
				prices: {},
				cakes: []
			}
		},
		created(){
			db.collection("products").where('category', '==', 'Bakedoor Classics').get()
			.then(querySnapshot => {
				querySnapshot.forEach(doc => {
					let cake = doc.data()
					let flavour = cake.flavour
					this.cakes.push(cake)
					this.prices[flavour] = cake.variant['price']
				})
				bus.$emit("show_loading", false)
			})			
		},		
		computed: {
		},
		methods:{
			getItemPrice(){
				let selectedWeightIndex = this.weights.indexOf(this.selectedWeight)
				if(Object.keys(this.prices).length == 0) { return null }
				return this.prices[this.selectedFlavour][selectedWeightIndex]				
			},			
			getItemDiscount(){
				return .25
			},
			addItemToCart(){
				let cake = this.cakes.find(cake => cake.flavour == this.selectedFlavour)
				delete cake['variant']
				cake['weight'] = this.selectedWeight
				cake['price'] = this.getItemPrice()
				cake['qty'] = 1
				this.cart.push(cake)
		    	this.$toasted.show("Cake added to cart successfully <i class='icon-ok-circle text-success'></i>")
			}
		},
		mixins: [
			cartMixin
		]
	}
</script>