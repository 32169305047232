<template>
    <div class="full-height">        
        <!-- <div class="cross">
            <i v-on:click="$router.go(-1)" class="icon-cancel-circle text-dark"></i>    
        </div>         -->
        <div class="container-fluid" style="padding: 0px 0px !important;background:white !important;">
            <div class="row no-gutters center">
                <div class="col col-md-6 offset-md-3" style="padding: 0px 0px !important;">
                    <img style="max-height:60vh;width:100%;object-fit:cover;" class="animated fadeIn" v-bind:src="productData.url" alt="">                    
                </div>
            </div>  
            <div class="row center">
                <div class="col-10 offset-1">
                    <h2 style="margin-bottom:10px;"> {{ productData.name }} </h2>
                    <p style="margin-bottom:20px;" v-if="!!productData.description">
                        <em> {{ productData.description }} </em>
                    </p>
                     <p>
                        <strong>Flavour:</strong> {{ productData.flavour }} | <strong>Starts at:</strong> Rs {{ productPrice }}
                     </p>                                        
                    <br>
                    <!-- <p><small>"{{ quote }}"</small> </p> -->
                </div>
            </div>             
                <div class="text-center" style="padding:0 10px;padding-bottom:10px;">
                  <button
                    class="btn-wide bg-dark text-white"
                    style="font-weight: bold;max-width:300px;"
                    v-on:click="goBack()"
                  >                    
                    <i class="icon-left"></i>  &nbsp;
                    GO TO ALL PRODUCTS
                  </button>
                     <p style="margin-top:20px;">
                       <em>
                            For more customizations (e.g. - different color) please mention in the optional instructions during checkout                               
                       </em>
                     </p>                   
                     <p style="margin-top:15px;">
                       <em>
                            OR
                       </em>
                     </p>
                     <div class="flex-column align-items-center justify-content-center" style="padding-bottom:10px;">
                        <div class="flex-row space-around align-items-center margin-top-10" style="width:200px;">
                            <a href="tel:6001736718" data-rel="external" class="remove-btn-styles bg-default" style="display:inline-block;padding:10px 20px;border-radius:8px;">                      
                            CALL
                            </a>
                            |                                        
                            <div @click="formWAText" class="cursor-pointer">
                                <img width="30px" src="@/assets/whatsapp.png" alt="">  
                            </div>                            
                        </div>
                        <div style="margin-top:20px;">
                            Contact: +917578025236    
                        </div>                         
                    </div>                   
                </div>            
        </div>  
  </div>
</template>


<script>

import {bus} from '../main';
import {db} from '../database'

export default {    
  beforeRouteEnter(to, from, next) {
    next((vm) => {
        if(from.name == "Products") {
            vm.isInternalLink = true
        }
        next()
    });
  },    
   metaInfo(){
     return {      
        title: `${this.productData.name} | ${this.productData.flavour} | Price ${ !!this.productData.variant? this.productData.variant.price[0] : '' }`        
    }
   },        
    props: ['cart','data'],
    data(){
        return {
            isInternalLink: false,
            image: '',
            quote: 'Made With Love By Cherryfrost',
            productData: {},
            currentYPos: 0
        }
    },    
    computed: {
        // productInfo(){
        //     if(this.data.length == 0) {
        //         return this.productData
        //     } else {
        //         return this.data
        //     }
        // }
        productPrice() {
            return !!this.productData.variant? this.productData.variant.price[0] : null
        }
    },
    created() {        
        bus.$emit('show_loading', false);                                
        const productId = this.$route.params.id        
        bus.$emit('show_loading', true)
        db.doc("products/"+productId).get().then(snap => {
            this.productData = snap.data()
            bus.$emit('show_loading', false);                
        })        
        this.currentYPos = this.$route.query.currentYPos
    },
    methods: {
        goBack() {
            if(this.isInternalLink) {
                this.$router.go(-1)
            } else {
                this.$router.push('/menu/Bakery')
            }
        },
        formWAText() {
            let msg = `Request for customization | Product Code - ${this.productData.id}`
            window.open('https://wa.me/+917578025236?text='+encodeURI(msg), '_blank')            
        }
    },
    destroyed() {        
        bus.$emit('hide_nav', true);
        bus.$emit('scrollToPos', this.currentYPos)
    }
}
</script>

<style scoped>

    .cross {
        position: absolute;
        z-index: 10;
        color: white;
        width: 100%;
        max-width: 760px;
        text-align: center;
        bottom: 40px;
        font-size: 30px;
        
    }

</style>

