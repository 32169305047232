<template>
  <div class="margin-top-15">
    <div>
      <div
        class="container bg-white material-card card"
        style="padding-bottom: 10px;"
      >
        <div class="row no-gutters">
          <div class="col-12 center">
            <strong class="text-primary">|</strong> &nbsp;
            <small
              >STARTS AT JUST ₹500 &nbsp;
              <strong class="text-primary">|</strong>
            </small>
          </div>          
          <!-- <div class="col-12">
            <div class="row" >
              <div class="col-12">
                <p><strong>MOBILE: </strong></p>
                <div class="row no-gutters">
                  <div class="col-2 center">
                    <div
                      style="padding: 7px 10px; font-size: 14px; height: 15px;"
                    >
                      +91
                    </div>
                  </div>
                  <div class="col-10">
                    <input
                      type="text"
                      style="width:100%;"
                      v-model.number="phone"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-12">
						<h3 class="lead-text">Select a Flavour</h3>
						<select name="" id="" v-model="selectedFlavour">
							<option v-for="(flavour,index) in flavours" :key="`flavour-${index}`">
								{{ flavour }}
							</option>
						</select>						
						<h3 class="lead-text">Select Weight</h3>
						<select v-model="selectedWeight">
							<option :key="weight" :value="weight" v-for="weight in weights">{{ weight }}</option>
						</select>
					</div>
          <div class="col-12">
                <p><strong>MOBILE: </strong></p>
                <div class="row no-gutters">
                  <div class="col-2 center">
                    <div
                      style="padding: 7px 10px; font-size: 14px; height: 15px;"
                    >
                      +91
                    </div>
                  </div>
                  <div class="col-10">
                    <input
                      type="text"
                      style="width:100%;"
                      v-model.number="phone"
                      pattern="[0-9]*" inputmode="numeric"
                    />
                  </div>
                </div>
          </div>
          <div class="col-12">
            <div v-if="submitting">
              <em>Submitting...</em>
            </div>
            <button v-else @click="addItemToCart()" class="btn-wide btn-primary">
              ENQUIRE NOW
            </button>
          </div>
        </div>
      </div>
      <div
        class="container bg-white material-card"
        style="border-top: 3px solid #00A651;"
      >
        <div class="row no-gutters">
          <div class="col-12">
            <h3 class="heading-text">HOW IT WORKS</h3>
            <p>
              Submit the weight and flavour for your cake. Then we will reach out to you on your provided contact for additional customizations.
            </p>
            <p>
              <small class="strong"> Have any queries? Call 7578025236</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from './../main'
import cartMixin from "./../cartMixin";
import { db } from "@/database.js"

export default {
  props: ["cart", "user"],
  data() {
    return {
      flavours: [
        "Vanilla",
        "Butterscotch",
        "Chocolate",
        "Pineapple",
        "Blueberry",
        "Black Forest",
        "White Forest",
        "Red Velvet"
      ],
      weights: ["0.5 Kg", "1 Kg", "Custom"],
      selectedFlavour: "Vanilla",
      selectedWeight: "0.5 Kg",
      prices: {},
      cakes: [],
      phone: null,
      submitting: false
    };
  },
  created() {
    setTimeout(() => {
      bus.$emit("show_loading", false)
    }, 100)
  },
  computed: {},
  methods: {    
    addItemToCart() {
      this.submitting = true
      let enquiry = {
        flavour: this.selectedFlavour,
        weight: this.selectedWeight,
        contact: this.phone,
        timestamp: Date.now()
      }
      const enquiresRef = db.collection("enquires")
      const docId = enquiresRef.doc().id
      enquiresRef.doc(docId).set({
        id: docId,
        ...enquiry
      })
      .then(() => {
        this.$toasted.show(
          "Enquiry Submitted successfully! <i class='icon-ok-circle text-success'></i>"
        );        
      })
      .catch(err => {
        console.log(err)
        this.$toasted.error("Something Went Wrong.")
      })
      .finally(() => {
        this.submitting = false
      })
    },
  },
  mixins: [cartMixin],
};
</script>
