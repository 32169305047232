import {bus} from './../main'

export default {
	beforeRouteEnter (to, from, next) {
		bus.$emit('show_loading', true)
		next();     
	},    
	beforeRouteLeave (to, from , next) {
		bus.$emit('show_loading', true)
		next()
	},        	
	methods: {
		hideNav(){
        	bus.$emit('hide_nav', true)
		},
		showPreloader(){
			bus.$emit('show_loading', true)
		},
		hidePreloader(){
			bus.$emit('show_loading', false)
		}		
	}
}