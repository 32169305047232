<!-- 1 database call to get the items -->
<template>
  <div v-if="showProducts">
    <div class="container-fluid">
      <div class="row" style="margin-left: 0px; margin-right: 0px">
        <div class="col-12 p-0">
          <div class="hero" style="margin-top:20px">
            <flickity />
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row" style="margin-left: 0px; margin-right: 0px">
        <div class="col-md-12">
          <div>
            <!-- <div v-if="currentTimeInHours < 15">
              <p class="text-center text-success" style="margin-top:20px;margin-bottom:0;">
                <i class="icon-ok-circle"></i> 
                &nbsp; Order before 3pm
                for same day delivery.
              </p>                            
            </div> -->
            <!-- <p v-else-if="currentTimeInHours < 21" class="alert-warning">
              Hey! Its past 7pm. If you still need a cake today before 9pm              
              >
            </p> -->
            <!-- <p v-else class="text-primary">
              <strong>Note:</strong> <br />
              Cake Delivery on the same day halts after 9pm.
            </p> -->

            <!-- <div class="container-fluid margin-top-10">
        <div class="row">
          
          <div class="col-7">
            <div class="hero-card">
              <h2>
                All Cakes Homemade
              </h2>
              <div>
                Delivered to you with love
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="row">
              <div
                @click="handleScrollToCategory('custom cakes')"
                class="col-12 cursor-pointer"
                role="button"
                style="padding-bottom:7.5px;"
              >
                <div class="hero-card-lite">
                  <h2>
                    Custom Cakes
                  </h2>
                </div>
              </div>
              <div
                @click="handleScrollToCategory('Out of the box')"
                class="col-12 cursor-pointer"
                role="button"
                style="padding-top:7.5px;"
              >
                <div class="hero-card-lite alt-color">
                  <h2>
                    Out of the box Cakes
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
            <div class="container-fluid">
              <div class="col-md-12">
                <div class="homemade-custom-cake">
                  <!-- <div class="container-fluid margin-top-10">
        <div class="row">
          
          <div class="col-7">
            <div class="hero-card" >
              <h2>
                All Cakes Homemade
              </h2>
              <div>
                Delivered to you with love
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="row">
              <div
                @click="handleScrollToCategory('custom cakes')"
                class="col-12 cursor-pointer"
                role="button"
                style="padding-bottom:7.5px;"
              >
                <div class="hero-card-lite">
                  <h2>
                    Custom Cakes
                  </h2>
                </div>
              </div>
              <div
                @click="handleScrollToCategory('Out of the box')"
                class="col-12 cursor-pointer"
                role="button"
                style="padding-top:7.5px;"
              >
                <div class="hero-card-lite alt-color">
                  <h2>
                    Out of the box Cakes
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="margin-left: 0px; margin-right: 0px">
        <div class="col-md-4 cat-colmn">
          <div
            class="material-card sticky"
            style="background: white; padding: 10px 20px; border-radius: 8px; margin-top: 20px"
          >
            <div slot="header"></div>
            <div slot="body" class="center">
              <template v-for="category in itemCategories()">
                <div
                  class="cursor-pointer"
                  v-bind:class="{
                    'border-left-primary-3': category == currentCategory,
                  }"
                  style="line-height: 40px;font-size: 16px;letter-spacing: 1px;"
                  role="button"
                  @click="handleScrollToCategory(category)"
                  :key="`cat-${category}`"
                >
                  {{ category }}
                </div>
              </template>
              <div class="container-fluid margin-top-15">
                <div class="row">
                  <!-- <div class="col-12 cursor-pointer" role="button" @click="handleScrollToCategory('Out of the box')">
                <div class="goto-modal-card">
                  Out of the Box Cakes
                </div>
              </div> -->
                  <div class="col-12">
                    <div @click="handleScrollToCategory('custom_cakes')" class="goto-modal-card">
                      Customized Cakes
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div class="col-md-8 p-0" style="margin-top:10px;">
          <p class="center strong text-dark" style="margin-bottom: -10px;">
            <small
              class="material-card bg-white"
              style="letter-spacing: 1px;padding:5px 15px;border-radius: 8px;"
            >
              <!-- Have any queries? Call 7578025236</small -->
              For any query, Call/WA - +917578025236</small
            >
          </p>

          <div id="products-wrapper">
            <div ref="products">
              <div class="container-fluid material-card bg-white" style="padding:0 7.5px !important;">
                <div
                  class="row no-gutters animated"
                  v-bind:class="{
                    fadeOut: fadeOutItems,
                    fadeIn: !fadeOutItems,
                  }"
                >
                  <div id="topElement"></div>
                  <template v-for="(itemCategory, index) in itemCategories()">
                    <div
                      v-bind:key="index"
                      v-bind:id="categoryId(itemCategory)"
                      class="col-12"
                      style="padding-bottom: 15px;"
                      v-bind:style="{
                        'border-top': index > 0 ? '4px solid #f5f6fa' : '',
                      }"
                    >                    
                      <br />
                      <h2 class="text-dark center" style="font-family:Raleway;">
                        <strong class="text-primary">|</strong> &nbsp;
                        {{ itemCategory.toUpperCase() }}
                        &nbsp; <strong class="text-primary">|</strong>
                      </h2>
                      <p class="text-center" v-if="!!bakeryDescriptions[index]" style="color:#676767;">
                        {{ bakeryDescriptions[index].text }}
                      </p>
                      <div v-if="categoryHasImage(index)" class="category-banner">
                        <img style="border-radius:8px;" :src="bakeryDescriptions[index].image" alt="">
                      </div>
                    </div>
                    <template
                      v-for="(item, index) in itemsInCategory(itemCategory)"
                    >
                      <transition
                        :key="`itemsInCategory-${index}`"
                        enter-active-class="animated fadeIn"
                        leave-active-class="animated fadeOut faster"
                      >
                        <!-- <div
                    v-if="!checkFilter(item.veg)"
                    class="col-6 col-sm-6 card-lite flex-column space-between animated"
                  > -->
                        <div
                          v-if="displayItemInCategory(itemCategory, index)"
                          class="col-6 col-md-4 card-lite flex-column space-between animated"
                        >
                          <div>
                            <div
                              class="card-10 product-container"
                              style="padding-top: 5px;overflow-y: hidden;"
                            >
                              <div
                                style="background-color: #f5f6fa;height: 100%;"
                              >
                                <clazy-load                                  
                                  v-bind:src="item.url"
                                >
                                  <img
                                    style="border-radius:8px;object-fit:cover;"
                                    v-on:click="showProduct(item)"
                                    v-bind:src="item.url"
                                    alt=""
                                    v-bind:class="{
                                      greyscale: !item.available,
                                    }"
                                  />
                                </clazy-load>
                              </div>
                            </div>
                            <div style="margin-bottom:10px;">
                              <span style="font-size:14px; ">
                                {{ item.name }}
                              </span>
                              <br />
                              <span
                                ><small class="text-dark strong">{{
                                  item.flavour
                                }}</small></span
                              >
                            </div>
                          </div>
                          <div v-if="item.available">
                            <div >
                              <span>
                                <big
                                  >&#8377;{{
                                    getItemPrice(
                                      !!item.variant.price
                                        ? item.variant.price[0]
                                        : null,
                                      0
                                    )
                                  }}</big
                                >
                                &nbsp;
                                <del class="text-dark"
                                  >&#8377;{{
                                    getItemActualPrice(
                                      !!item.variant.price
                                        ? item.variant.price[0]
                                        : null,
                                      item.discount
                                    )
                                  }}</del
                                >
                                <span class="text-success strong">
                                  &nbsp; {{ item.discount * 100 }}% OFF</span
                                >
                              </span>
                            </div>
                            <div class="flex-row" style="margin-top:10px;">
                              <span
                                v-if="getQty(item.id) > 0"
                                class="flex-row align-items-center space-between text-primary"
                                style="padding-bottom: 7px;"
                              >
                                <span
                                  class="animated fadeInRight"
                                  v-on:click="changeQty(item, 1)"
                                >
                                  <i class="icon-plus font-size-18"></i>
                                </span>
                                <span
                                  class="center"
                                  style="width:35px;color:black;"
                                  ><strong class="animated fadeIn">{{
                                    getQty(item.id)
                                  }}</strong></span
                                >
                                <span
                                  class="animated fadeInLeft"
                                  v-on:click="changeQty(item, 0)"
                                  ><i class="icon-minus font-size-18"></i
                                ></span>
                              </span>
                              <span v-else>
                                <div style="width:95px;">
                                  <button
                                    v-on:click="changeQty(item, 1)"
                                    class="btn-primary btn-md"
                                  >
                                    <i class="icon-plus-circle"></i> &nbsp; ADD
                                  </button>
                                </div>
                              </span>
                            </div>                            
                          </div>
                            <div v-else>
                            <small style="color: #696969;"
                              >BACK IN STOCK SOON</small
                            >
                          </div>
                        </div>
                      </transition>
                    </template>
                    <div
                      v-if="
                        !fullViewCategories.includes(itemCategory) &&
                          itemsInCategory(itemCategory).length > 6
                          && index !== 0
                      "
                      :key="`my-${index}`"
                      class="col-12 text-center"
                      style="margin-bottom:10px;"
                    >
                      <!-- <button @click="addToFullViewCategories(itemCategory)" class="remove-btn-styles text-primary">
                      SEE MORE {{ itemCategory.toUpperCase() }} CAKES
                  </button> -->
                      <button
                        style="flex-basis:70%;border:2px solid #f3f3f3;"
                        @click="addToFullViewCategories(itemCategory)"
                        class="btn-wide bg-white text-primary strong"
                      >
                        SEE MORE {{ itemCategory.toUpperCase() }} CAKES &nbsp;<i
                          class="icon-right"
                        ></i>
                      </button>
                    </div>
                    <div id="custom_cakes" v-if="index == 0" :key="`custom_bento_${index}`">
                      <h2 class="text-center">NEED A CUSTOM TIFFIN CAKE?</h2>
                      <p class="text-center">We will make it using any reference image or idea you provide.</p>
                      <router-link to="/custom-bento-cake" custom>
                        <img src="https://storage.googleapis.com/cherryfrost_master/_assets/custom_bento_cake_banner_1.jpg" alt="">
                      </router-link>
                      <router-link
                        to="/custom-bento-cake"
                        custom
                        v-slot="{ navigate }"
                        style="margin:15px 0;"
                      >
                        <button
                          @click="navigate"
                          role="link"
                          class="remove-btn-styles btn-wide cursor-pointer bg-default"
                          style="color:black;"
                        >
                          GET STARTED
                        </button>
                      </router-link>                      
                    </div>                    
                    <br />
                  </template>
                </div>
              </div>
            </div>

            <div              
              class="material-card bg-white margin-top-15"              
            >            
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div class="custom-card">
                      <h2>CUSTOMIZED REGULAR CAKES</h2>
                      <p>
                        Get your dream cake exactly as you desire.
                      </p>
                      <router-link
                        to="/custom-cakes"
                        custom
                        v-slot="{ navigate }"
                      >
                        <button
                          @click="navigate"
                          role="link"
                          class="remove-btn-styles btn-wide material-card cursor-pointer"
                          style="background-color:white;color:black;"
                        >
                          GET STARTED
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Options Drawer -->
            <div v-if="show_options">
              <div
                class="animated slideInUp faster"
                v-bind:class="
                  $mq > 'md'
                    ? 'options-bottom'
                    : ['options-center', 'options-center-fix']
                "
              >
                <template v-if="mode == 1">
                  <p class="flex-row align-items-center">
                    <small class="flex-row align-items-center" style="padding-top: 10px;padding-right:10px;"
                      >
                      <img :src="current_item.url" alt="" style="border-radius:8px;object-fit:cover;width:50px;height:50px;">
                      <span style="padding-left:10px;">
                        <span class="strong" style="font-size: 18px;">
                          {{
                          current_item.name
                        }}</span
                        ><br />
                        <span style="line-height: 20px;">
                        {{ current_item.flavour }}  
                        </span>                        
                      </span>                      
                    </small>
                    <small v-on:click="hideOptions()"
                      ><i class="icon-cancel-circle text-dark bg-default"></i
                    ></small>
                  </p>
                  <p v-if="current_item.showDescriptionOnOptions">
                    <em>{{ current_item.description }}</em>
                  </p>
                  <div
                    class="margin-top-10"
                    style="margin-bottom:5px;margin-left:10px;"
                  >
                    <!-- <div
                      class="margin-top-10"                    
                      v-for="(field, index) in current_item.extraFields"
                      :key="`field-title-${index}`"
                    >
                      <div
                        style="flex-basis:20%;"
                      >
                        {{ field.title }}
                      </div>
                      <div
                        :key="`field-value-${index}`"
                        style="flex-basis:60%;"
                      >
                        <select
                          @change="
                            changeSelectedExtraFieldVal($event, field.id, index)
                          "
                        >
                          <option
                            v-for="value in field.value"
                            :key="value"
                            :value="value"
                          >
                            {{ value }}
                          </option>
                        </select>
                      </div>
                    </div> -->

                    <div class="row">
                      <div class="col-md-6 flex-row align-items-center margin-top-10" 
                        v-for="(field, index) in current_item.extraFields"
                        :key="`field-title-${index}`">
                          <div
                            style="flex-basis:20%;"
                          >
                            {{ field.title }}
                          </div>
                          <div
                            :key="`field-value-${index}`"
                            style="flex-basis:80%;"
                          >
                            <select
                              @change="
                                changeSelectedExtraFieldVal($event, field.id, index)
                              "
                            >
                              <option
                                v-for="value in field.value"
                                :key="value"
                                :value="value"
                              >
                                {{ value }}
                              </option>
                            </select>
                          </div>
                      </div>
                    </div>


                  </div>
                  <div
                    v-show="current_item.isMultiFlavour"
                    class="flex-row align-items-center margin-top-10"
                    style="margin-bottom:5px;"
                  >
                    <div style="flex-basis:20%;">
                      Flavour
                    </div>
                    <div style="flex-basis:60%;">
                      <select
                        @change="onSelectedFlavourChange($event)"
                        v-model="currentItemFlavour"
                      >
                        <option
                          v-for="flavour in multiFlavourOptions"
                          :key="flavour"
                          :value="flavour"
                        >
                          {{ flavour }}
                        </option>
                      </select>
                    </div>
                  </div>
                    <p style="padding:10px;display: inline-block;margin-top: 0px;margin-bottom: 0px;" v-if="!disableVegFilter && currentSubtype == 'Bakery'">
                        <small class="flex-row align-items-center">
                            <div>
                                <input type="checkbox" v-model="filterItems" id="cbx" style="display:none"/>
                                <label for="cbx" class="toggle"><span></span></label>  
                            </div>                                
                            <strong class="text-dark" v-bind:class="{'text-success':filterItems}"> &nbsp; MAKE IT EGGLESS</strong>
                        </small>
                    </p>
                  <nav>
                    <p
                      class="material-card"
                      v-for="(price, index) in current_item.variant.price"
                      v-on:click="selectItemVarient(index)"
                      :key="`variant-${index}-${price}`"
                    >
                      <span class="strong">
                        <big>{{ current_item.variant.type[index] }}</big>
                      </span>
                      <small v-if="currentSubtype == 'Eats'"
                        >[{{ current_item.variant.desc[index] }}]</small
                      >
                      <span
                        style="line-height: 25px;border-top: 1px solid #4d4d4d;width: 100%;text-align: center;margin-top: 10px;padding-top: 10px;"
                        >&#8377;{{ getItemPrice(price, index) }}
                      </span>
                    </p>
                  </nav>
                </template>
                <template v-else-if="mode == 0">
                  <p>
                    <small>Delete?</small
                    ><i
                      v-on:click="hideOptions()"
                      class="icon-cancel-circle text-dark"
                    ></i>
                  </p>
                  <nav>
                    <p
                      class="material-card"
                      v-for="(item, index) in currentItemSelectedVarients"
                      :key="`hola-${index}`"
                      v-on:click="deleteItemFromCart(item)"
                    >
                      <span class="strong">
                        <big>{{ item.weight }}</big>
                      </span>
                      <span
                        style="line-height: 25px;border-top: 1px solid #4d4d4d;width: 100%;text-align: center;margin-top: 10px;padding-top: 10px;"
                        >&#8377;{{ item.price }}
                      </span>
                    </p>
                  </nav>
                </template>
              </div>              
            </div>
            <br />
            <br />

            <!-- Goto Modal Button -->
            <div
              v-if="items.length > 0 && !loading"
              class="gotoCategoryBtn animated pulse"
              v-bind:class="{ 'bottom-lift': !isCartEmpty }"
            >
              <div
                id="changeCateogoryBtn"
                @click="getCategoryPos"
                class="text-dark strong cursor-pointer border-gradient-purple "
                style="padding: 4px;display: inline-block;border-radius: 50px;"
                role="button"
              >
              <div class="module">
                Go To Category &nbsp;<i class="icon-direction"></i>
              </div>
              </div>
             
            </div>            

            <!-- Prompt User For Email Modal -->
            <Modal
              v-if="promptUserForPhoneModal"
              @close="promptUserForPhoneModal = false"
            >
              <div slot="header">
                <h3>Enter Your Mobile Number -</h3>
              </div>
              <div slot="body">
                <form v-on:submit.prevent>
                  <input
                    @keyup.enter="
                      submitInterestedUserDetails(interestedUserPhone)
                    "
                    type="text"
                    v-model="interestedUserPhone"
                  />
                  <button
                    @click="submitInterestedUserDetails(interestedUserPhone)"
                    class="btn-wide bg-success text-white margin-top-10"
                  >
                    DONE!
                  </button>
                </form>
              </div>
              <div slot="footer">
                <p>
                  We will reach you first when the service starts!
                </p>
              </div>
            </Modal>

            <!-- Goto Modal -->
            <Modal v-if="showGotoModal" @close="showGotoModal = false">
              <div slot="header"></div>
              <div slot="body" class="center">
                <template v-for="category in itemCategories()">
                  <div
                    class="cursor-pointer"
                    v-bind:class="{
                      'border-left-primary-3': category == currentCategory,
                    }"
                    style="line-height: 40px;font-size: 16px;letter-spacing: 1px;"
                    role="button"
                    @click="handleScrollToCategory(category)"
                    :key="`cat-${category}`"
                  >
                    {{ category }}
                  </div>
                </template>
                <div class="container-fluid margin-top-15">
                  <div class="row">
                    <!-- <div class="col-12 cursor-pointer" role="button" @click="handleScrollToCategory('Out of the box')">
                <div class="goto-modal-card">
                  Out of the Box Cakes
                </div>
              </div> -->
                    <div class="col-12">
                      <div @click="handleScrollToCategory('custom_cakes')" class="goto-modal-card">
                        Customized Cakes
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div slot="footer"></div>
            </Modal>
          </div>              
        </div>
      </div>
    </div>

<!-- Cart Info Bottom Nav -->
            <div v-if="!show_options && !loading" class="bottom-product-nav">
              <div
                v-if="numItemsInCart() > 0"
                class="nav-bottom bg-white flex-row space-between align-items-center material-card animated bottom-nav"
                v-bind:class="{ slideInUp: animationStatus }"
              >
                <div style="flex-basis: 60%;">
                  <strong> TOTAL </strong> - &#8377;{{ getCost() }} ({{
                    numItemsInCart()
                  }}<span v-if="numAddonsInCart > 0">
                    + {{ numAddonsInCart }}</span
                  >
                  {{ numItemsInCart() > 1 ? "Items" : "Item" }})
                </div>
                <div style="flex-basis: 40%;">
                  <button
                    class="btn-wide bg-success text-white"
                    style="font-weight: bold;"
                    v-on:click="goToCart()"
                  >
                    GO TO CART &nbsp;<i class="icon-right"></i>
                  </button>
                </div>
              </div>


              <!-- <div
          v-else
          class="nav-bottom text-success bg-white material-card center flex-row dead-center"
          style="padding-top: 10px;padding-bottom: 10px;max-width: 768px;letter-spacing: 1px;"
        >
          <span>{{ bottomText }}</span> &nbsp;
        </div> -->
              <!-- <div style="height: 40px;width: 100%;"></div> -->
            </div>
            <!-- end  -->       
  </div>
</template>

<script>
import { bus, messaging } from "./../main";
import cartMixin from "./../cartMixin";
import EnterAndLeaveRouteMixin from "./../mixins/EnterAndLeaveRouteMixin";
import { db } from "./../database";
const itemsRef = db.collection("products");
import Modal from "./Modal";
import { VueClazyLoad } from "vue-clazy-load";
import ProductsHeader from "./parts/ProductsHeader";
import Flickity from "./Flickity.vue";
import axios from 'axios'

export default {
   metaInfo: {      
      title: "Explore Cakes",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Fresh and delicious cakes made by talented home cooks",
        },
      ],      
  },  
  props: ["cart", "user", "loading"],
  data() {
    return {
      bottomText: "All Eggless. Delivery within 2 hours",
      interestedUserPhone: "",
      disableVegFilter: false,
      radioValue: false,
      showProducts: false,
      eggless: false,
      filterItems: false,
      veg: false,
      showGotoModal: false,
      current_item: "",
      eatsCategories: [
        "Quick Meals",
        "Indian Curries",
        "Sabji and Dal",
        "Roti and Rice",
      ],
      bakeryCategories: [
        'Tiffin Cake'
      ],
      animationStatus: false,
      items: [],
      show_options: false,
      mode: 1,
      itemsCollection: {
        Bakery: [],
        Eats: [],
      },
      selecctedCollection: "Bakery",
      fadeOutItems: false,
      currentCategory: "Quick Munches",
      scrollToPos: 0,
      promptUserForPhoneModal: false,
      userInterested: false,
      fullViewCategories: [],
      currentItemFlavour: "",
      currentItemVarients: null,
      multiFlavourOptions: [],
      current_item_prices: [],
      fetchedCategories: [],
      bakeryDescriptions: []
    };
  },
  created() { 
    
    this.fullViewCategories = Object.keys(this.$route.query)    
    
    axios.get('https://cherryfroststore-default-rtdb.asia-southeast1.firebasedatabase.app/categories.json')    
    .then(res => {            
      this.fetchedCategories = Object.values(res.data)
      this.fetchedCategories.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))            
      // this.bakeryCategories = this.fetchedCategories.map(item => item.title)
      this.bakeryCategories = ['Tiffin Cake']      
      this.bakeryDescriptions = this.fetchedCategories.map(item => { 
        return {
          image: item.url,
          text: item.description 
        }        
      })
    });

    bus.$on("scrollToPos", (data) => {      
      setTimeout(() => {
        window.scroll(0, data) 
      }, 700)
    });

    this.userInterested = localStorage.getItem("userInterested") != null;
  },  
  mounted() {
    bus.$emit("show_loading", true)

    let filteredItemsRef = itemsRef
      .where("subtype", "==", this.$route.params.item)
      .orderBy("position");
    
    filteredItemsRef.onSnapshot((querySnapshot) => {
      this.items.length = 0;
      
      querySnapshot.forEach((doc) => {
        let data = doc.data()
        if(data.isLive != false) {
          data.variant.price = Array.from({ length: data.variant.price.length }, (_, i) => 250);
          this.items.push(data);
        }
      });
      
      this.$nextTick(() => {
        this.changeDisplayProductsStatus()
      })    

    });

  },
  methods: {
    changeSelectedExtraFieldVal(event, fieldId, index) {      

      let val = event.target.value;      
      this.current_item.selectedExtraField[fieldId] = val;         
      let selectedValIndex = this.current_item.extraFields[index].value.indexOf(val)
      this.current_item.variant.price = this.current_item_prices.map(item => item + Math.floor(item * this.current_item.extraFields[index].priceChange[selectedValIndex]))
      
      this.$forceUpdate();
    },
    categoryHasImage(index) {
      return !!this.bakeryDescriptions[index]? !!this.bakeryDescriptions[index].image : false
    },
    onSelectedFlavourChange(event) {
      let newFlavour = event.target.value;
      let variantItem = this.currentItemVarients.find(
        (v) => v.flavour == newFlavour
      );
      this.current_item.variant = variantItem;
      // this.$forceUpdate()
    },
    displayItemInCategory(category, index) {      
      return this.fullViewCategories.includes(category) || category == 'Tiffin Cake' ? true : index < 6;
    },
    addToFullViewCategories(category) {
    this.$router.replace({ query: Object.assign({}, this.$route.query, { [category]: true }) });
      this.fullViewCategories.push(category);
    },
    showViewCondition(index) {
      return (index + 1) % 6 == 0;
    },
    submitInterestedUserDetails(detail) {
      // this.userInterested = true;
      // this.promptUserForPhoneModal = false;
      // this.$http
      //   .post("https://bakedoor-46954.firebaseio.com/interestedUsers.json", {
      //     emailOrPhone: detail,
      //   })
      //   .then(() => {
      //     this.$toasted.show("We have saved your interest!");
      //     localStorage.setItem("userInterested", true);
      //   });
    },
    subscribeUser() {
      let interestedUser;
      if (this.user) {
        interestedUser = this.user.email;
        this.submitInterestedUserDetails(interestedUser);
      } else {
        this.promptUserForPhone();
      }
    },
    promptUserForPhone() {
      this.promptUserForPhoneModal = true;
    },
    checkFilter(veg) {
      return (
        veg == false &&
        this.filterItems == true &&
        this.$route.params.item != "Bakery"
      );
    },
    handleScrollToCategory(category) {
      this.showGotoModal = false;
      let topElement;
      this.currentSubtype == "Eats"
        ? (topElement = document.getElementById(
            this.categoryId(this.eatsCategories[0])
          ))
        : (topElement = document.getElementById(
            this.categoryId(this.bakeryCategories[0])
          ));
      let topElementPos = topElement.getBoundingClientRect().top;
      let id = this.categoryId(category);      
      let el = document.getElementById(id);
      let currentElementPos = el.getBoundingClientRect().top;
      let pos = currentElementPos - topElementPos;
      window.scroll({
        left: 0,
        top: pos+60,
        behavior: "smooth",
      });
    },
    changeDisplayProductsStatus() {
      bus.$emit("show_loading", false);
      this.showProducts = true;
      window.setTimeout(this.goToPreviousPos, 100);
    },
    goToPreviousPos() {
      window.scroll(0, this.scrollToPos);
    },
    getCategoryPos(category) {
      this.showGotoModal = true;
      if (window.scrollY < 30) {
        this.currentCategory = this.itemCategories()[0];
        return;
      }
      let categoryPositions = [];
      this.itemCategories().forEach((category) => {
        let categoryId = this.categoryId(category);
        let el = document.getElementById(categoryId);
        let distFromTop = el.getBoundingClientRect().bottom;
        categoryPositions.push(distFromTop);
      });
      categoryPositions.reverse();
      let x = categoryPositions.findIndex((pos) => pos < 200);
      this.currentCategory = this.itemCategories()[
        categoryPositions.length - x - 1
      ];
    },
    categoryId(itemCategory) {
      return itemCategory
        .split(" ")
        .join("_")
        .split("&")
        .join("N");
    },
    itemCategories() {
      if (this.$route.params.item == "Bakery") {
        return this.bakeryCategories;
      } else if (this.$route.params.item == "Eats") {
        return this.eatsCategories;
      }
    },
    imageLoc(itemName) {
      return "http://via.placeholder.com/350x140?text=" + itemName;
    },

    // *********************** METHODS FOR ADDING AND REMOVING ITEMS *************************
    // Open drawer with item variant options
    changeQty(item, mode) {
      this.currentItemVarients = item.variant;
      this.current_item = JSON.parse(JSON.stringify(item))
      this.current_item_prices = Object.assign([], this.current_item.variant.price)
      this.mode = mode;
      if (!!item.variant[0]) {
        this.current_item.variant = item.variant[0];
        this.currentItemFlavour = item.variant[0].flavour;
        this.multiFlavourOptions = this.currentItemVarients.map(
          (variant) => variant.flavour
        );
      } else {
        this.currentItemFlavour = "";
      }
      if (!!this.current_item.extraFields) {
        this.current_item["selectedExtraField"] = {};
        this.current_item.extraFields.forEach((field) => {
          this.current_item["selectedExtraField"][field.id] = field.value[0];
        });
      }
      this.$nextTick(() => {
        this.showOptions();
      });
    },
    showOptions() {
      bus.$emit("show_overlay", true);
      this.show_options = true;
      this.animationStatus = true;
      this.$forceUpdate();
    },
    hideOptions: function() {            
      this.show_options = false;
      this.animationStatus = false;
      bus.$emit("show_overlay", false);
    },
    selectItemVarient(variant_index) {
      let temp;
      this.current_item.price = this.current_item.variant.price[variant_index];
      this.current_item["weight"] = this.current_item.variant.type[
        variant_index
      ];
      if (this.current_item.isMultiFlavour) {
        this.current_item.flavour = `${this.current_item.flavour} (${this.currentItemFlavour})`;
      }
      if (this.filterItems && this.$route.params.item == "Bakery") {
        this.current_item["name"] += " (Eggless)";
        this.current_item.price += this.egglessPrices[variant_index];
        this.current_item["veg"] = true;
      }
      if (!!this.current_item.extraFields) {
        let str = "";
        this.current_item.extraFields.forEach((field, index) => {
          str += `${this.current_item.selectedExtraField[field.id]}`;
          if (index < this.current_item.extraFields.length - 1) {
            str += ", ";
          }
        });
        this.current_item.name = `${this.current_item.name}(${str})`;
      }

      if (
        (temp = this.cart.find(
          (item) =>
            item.id == this.current_item.id &&
            item.price == this.current_item.variant.price[variant_index] &&
            this.current_item.subtype != "Bakery"
        ))
      ) {
        temp.qty += 1;
        this.forceUpdateCart();
      } else {
        delete this.current_item.variant;
        this.current_item.qty = 1;
        this.cart.push(this.current_item);
      }
      this.hideOptions();
    },
    deleteItemFromCart(item) {
      if (item.qty > 1) {
        item.qty -= 1;
      } else {
        this.cart.splice(this.cart.indexOf(item), 1);
      }
      this.hideOptions();
      this.mode = 1;
    },
    getQty(item_id) {
      let items = this.cart.filter((item) => item.id == item_id);
      let total = 0;
      items.forEach((element) => {
        total += element.qty;
      });
      return total;
    },
    goToCart() {
      this.$emit("viewCart", this.cart);
      this.$router.push("/cart");
    },

    // *********************** METHODS FOR HANDLING CATEGORY SELECTIONS *************************
    showElement(el) {
      el.className = "animated fadeIn";
    },
    itemsInCategory(categoryName) {
      return this.items.filter((item) => item.category == categoryName);
    },

    // ********************* SHOWING PRODUCT INFO *************************
    showProduct(item) {
      bus.$emit("newSharedData", item);
      this.$router.push({
        path: "/product/" + item.id,
        query: { currentYPos: window.scrollY },
      });
    },
    getItemPrice(price, index) {
      if (!price) {
        return 100;
      }

      if (this.filterItems && this.$route.params.item == "Bakery") {        
        return price + this.egglessPrices[index];
      } else {
        return price;
      }
    },
    getItemActualPrice(price, discount) {
      return Math.round(this.getItemPrice(price, 0) / (1 - discount));
    },
  },
  computed: {
    currentSubtype() {
      return this.$route.params.item;
    },
    numAddonsInCart: function() {
      return this.cart.filter((item) => item.type == "addon").length;
    },
    currentItemSelectedVarients() {
      let temp = [];
      let items = this.cart.filter((item) => item.id == this.current_item.id);
      items.forEach((element) => {
        for (let i = 0; i < element.qty; i++) {
          temp.push(element);
        }
      });
      return temp;
    },
  },
  watch: {
    show_options(val) {
      if(!val) {
        this.current_item = ""
        this.filterItems = false  
      }
    }
  },
  components: {
    Flickity,
    Modal,
    "clazy-load": VueClazyLoad,
    "products-header": ProductsHeader,
  },
  mixins: [cartMixin, EnterAndLeaveRouteMixin],
  destroyed() {
    bus.$emit("show_overlay", false);
  },
};
</script>

<style scoped>
h2 {
  font-size: 18px;
}

.product-container {
  border-radius: 8px;
  height: 120px;
}

.bg-launching-soon {
  background: #f3f3f3; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #dadada,
    #f3f3f3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #dadada,
    #f3f3f3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.options-center-fix {
}

.border-left-primary-3 {
  border-left: 3px solid #e74c3c;
}

.gotoCategoryBtn {
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 30px;
  /* max-width: 768px; */
}

.bottom-lift {
  bottom: 80px;
}

.hero-card {
  height: 180px;
  /* background: url('https://www.bakefromscratch.com/wp-content/uploads/2020/02/Heilala-Cake-Web-696x557.jpg'); */
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0),
      #cf433636
    ),
    url("https://www.bakefromscratch.com/wp-content/uploads/2020/02/Heilala-Cake-Web-696x557.jpg");

  background-position: center;
  background-size: cover;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.custom-card {
  height: 180px;
  /* background: url('https://i.ndtvimg.com/i/2015-07/625-cakes-customised_626x350_61437647035.jpg'); */

  background-image: linear-gradient(
      to bottom,
      rgba(149, 149, 149, 0.438),
      rgba(149, 149, 149, 0.438)
    ),
    url("https://i.ndtvimg.com/i/2015-07/625-cakes-customised_626x350_61437647035.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
}

.hero-card-lite {
  background: #1abc9c;
  border-radius: 8px;
  padding: 0px 10px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  height: 82.5px;
  color: white;
}

.alt-color {
  background: #3498db;
}

.hero-card-lite h2 {
  font-size: 16px;
}

.goto-modal-card {
  padding: 10px;
  /* background: peachpuff; */
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
}

.bottom-nav {
  border-top: 1px solid #e2e1e0;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 768px;
}

.homemade-custom-cake {
  margin: 0px 0px;
}

.gotoCategoryBtn {
  display: none;
}

.product-container {
    height: 200px;
  }

  /* .bottom-nav{
    border-top: 1px solid #e2e1e0;padding-top: 10px;padding-bottom: 10px;max-width: 100%;
  } */

  .homemade-custom-cake {
    margin: 0px 20px;
  }

  .bottom-product-nav {
    margin: 0px auto;
    width: 768px;
  }

  .sticky{
    position: sticky;
    top: 80px;
  }

@media (max-width: 768px) {  
.border-gradient-purple {
  border-radius: 50px;
  /* max-width: 250px; */
  /* padding: 1rem; */
  position: relative;
  background: linear-gradient(to right,#ffc3cb,  #f15062);
}
  
.module {
  background: white;
  color: white;
  color: black;
  padding: 10px 20px;
  border-radius: 50px;
}

  .container-fluid {
    padding: 0 !important;
  }

  .product-container {
    height: 150px;
  }

  /* .bottom-nav{
    border-top: 1px solid #e2e1e0;padding-top: 10px;padding-bottom: 10px;max-width: 100%;
  } */

  .homemade-custom-cake {
    margin: 0px 20px;
  }

  .bottom-product-nav {
    margin: 0;
    width: 100%;
  }
  
  .gotoCategoryBtn {
    display: inline-block;
  }

  .cat-colmn{
    display: none;
  }

  .p-0 {
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    max-width: 1140px !important;
  }
}

@media (min-width: 992px) {  
  .container-fluid {
      max-width: 960px;
  }
}

@media (min-width: 768px) {
  .container-fluid {
      max-width: 720px;
  }
}


</style>
