<template>
  <div>
    <div style="margin-top:-5px;margin">
      <img class="bento_banner_alt_img" src="https://storage.googleapis.com/cherryfrost_master/_assets/custom_bento_banner_04.jpg" alt="">      
    </div>
    <div>
      <div
        class="container bg-white material-card card"
        style="padding-bottom: 10px;"
      >
        <div class="row no-gutters">
          <div class="col-12 center">
            <strong class="text-primary">|</strong> &nbsp;
            <small
              >STARTS AT JUST ₹199 &nbsp;
              <strong class="text-primary">|</strong>
            </small>
          </div>          
          <!-- <div class="col-12">
            <div class="row" >
              <div class="col-12">
                <p><strong>MOBILE: </strong></p>
                <div class="row no-gutters">
                  <div class="col-2 center">
                    <div
                      style="padding: 7px 10px; font-size: 14px; height: 15px;"
                    >
                      +91
                    </div>
                  </div>
                  <div class="col-10">
                    <input
                      type="text"
                      style="width:100%;"
                      v-model.number="phone"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-12">
						<h3 class="lead-text">Flavour</h3>
						<select name="" id="" v-model="selectedFlavour">
							<option v-for="(flavour,index) in flavours" :key="`flavour-${index}`">
								{{ flavour }}
							</option>
						</select>						
						<!-- <h3 class="lead-text">Select Weight</h3>
						<select v-model="selectedWeight">
							<option :key="weight" :value="weight" v-for="weight in weights">{{ weight }}</option>
						</select> -->
					</div>
          <div class="col-12">
                <h3 class="lead-text">Contact</h3>
                <div class="row no-gutters">
                  <div class="col-2 center">
                    <div
                      style="padding: 7px 10px; font-size: 14px; height: 15px;"
                    >
                      +91
                    </div>
                  </div>
                  <div class="col-10">
                    <input
                      type="text"
                      placeholder="Enter your whatsapp/phone number"
                      style="width:100%;"
                      v-model.number="phone"
                      pattern="[0-9]*" inputmode="numeric"
                      required
                    />
                  </div>
                </div>
          </div>
          <div class="col-12">
            <div v-if="submitting" class="text-center">
              <em>Submitting...</em>
            </div>
            <button v-else @click="addItemToCart()" class="btn-wide btn-success">
              NEXT
            </button>
          </div>
        </div>
      </div>
      <div
        class="container bg-white material-card"
        style="border-top: 3px solid #00A651;"
      >
        <div class="row no-gutters">
          <div class="col-12">
            <h3 class="heading-text">HOW IT WORKS</h3>
            <p>
              We will bake a bento cake based on your provided design/idea. Since it is a bento cake it would weigh around 250 grams (suitable for up to a group of 4)
            </p>
            <p>
              Delivery times would depend on how complex the desired cake is. Typical cakes can be delivered next day and design heavy cakes do not take more than 3 days.
            </p>
            <p>Starts at just ₹199. We always try to give you the best deal along with the best taste :)</p>
            <p>
              We are also open to any special requests so your imagination is the limit!
            </p>
            <p>
              <small class="strong"> Have any queries? Call 7578025236</small>
            </p>
          </div>
        </div>
      </div>
    </div>      

    <modal v-if="showConfirmedModal" @close="showConfirmedModal = false">
      <div slot="header">
            <h1 style="color:#1b1b1b;">Thank You</h1>	    
      </div>
      <div slot="body">
        <p>
          Please expect our call/message soon regarding your request.
        </p>
        <template v-if="!user">
          <p>
            Keep yourself signed in for a smooth order process.          
          </p>
          <p>
            <strong><router-link to="/sign-in" class="text-success">
              Sign In Now
            </router-link></strong>
          </p>
        </template>
      </div>
      <div slot="footer">	                
        <button @click="showConfirmedModal = false" class="btn-wide bg-default">
            OKAY
        </button>
      </div>
    </modal> 

  </div>
</template>

<script>
import { bus } from './../main'
import cartMixin from "./../cartMixin";
import { db } from "@/database.js"
import Modal from '../components/Modal.vue'

export default {
  props: ["cart", "user"],
  data() {
    return {
      showConfirmedModal: false,
      flavours: [
        "Vanilla",                
        "Chocolate",
        "Butterscotch",
        "Strawberry",
        "Blueberry",
        "Pineapple",
        "Red Velvet",
        "Black Forest",
        "White Forest",
        "Other"
      ],
      weights: ["0.5 Kg", "1 Kg", "Custom"],
      selectedFlavour: "Vanilla",
      selectedWeight: "0.5 Kg",
      prices: {},
      cakes: [],
      phone: null,
      submitting: false
    };
  },
  created() {
    setTimeout(() => {
      bus.$emit("show_loading", false)
    }, 100)
  },
  computed: {},
  methods: {    
    addItemToCart() {
      this.submitting = true
      let enquiry = {
        flavour: this.selectedFlavour,
        type: 'bento',
        weight: '0.25 KG',
        contact: this.phone,
        timestamp: Date.now()
      }
      const enquiresRef = db.collection("enquires")
      const docId = enquiresRef.doc().id
      enquiresRef.doc(docId).set({
        id: docId,
        ...enquiry
      })
      .then(() => {
        this.showConfirmedModal = true
        this.phone = null
        this.$toasted.show(
          "Enquiry Submitted successfully! <i class='icon-ok-circle text-success'></i>"
        );        
      })
      .catch(err => {
        console.log(err)
        this.$toasted.error("Something Went Wrong.")
      })
      .finally(() => {
        this.submitting = false
      })
    },
  },
  mixins: [cartMixin],
  components: {
    Modal
  }
};
</script>


<style scoped>
.bento_banner_alt_img {
  height: 70px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
</style>