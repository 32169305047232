<template>	
	<div class="material-card">	
		<div class="container bg-white">
		    <div class="row no-gutters">
		        <div class="col">            
		            <h3 class="header-font">Cherryfrost RECOMMENDATIONS</h3>                    
		        </div>
		    </div>
		    <div v-if="recommendations.length == 0" class="flex-row dead-center">
		    	<div class="row">
		    		<div class="col-12">	
		    			<beat-loader></beat-loader>
		    		</div>
		    	</div>
		    </div>
		    <div v-else class="row no-gutters">
		        <template v-for="recommendation in recommendations" >            
					<transition enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster">
						<div v-if="!itemInCart(recommendation)" class="col-12 card-lite">
							<img style="width: 100%;" src="http://via.placeholder.com/350x100" alt="">
							<div class="flex-row center-spaced" style="margin-top: 10px;">
								<div style="flex-basis: 65%;">
									{{ recommendation.name }} <br>
									<small>	{{ recommendation.items }} </small>
								</div>
								<div style="flex-basis: 35%;">
									<button @click="addRecommendationToCart(recommendation)" class="btn-wide text-white" style="background: linear-gradient(to right, #e95d4f,#e74c3c);">ORDER @{{ recommendation.price }}</button>            		
								</div>		            
							</div>
						</div>
					</transition>
		        </template>
		    </div>            
		</div>  
	</div>
</template>

<script>
	import {db} from './../../database'
	import BeatLoader from 'vue-spinner/src/BeatLoader.vue'

	export default {
		props: ['cart'],
		data(){
			return {
				recommendations: [],
				selectedRecommendations: []
			}
		},
		created(){
			const itemsRef = db.collection("products")
			itemsRef.where("subtype", "==", "recommended").onSnapshot(querySnapshot => {
				this.recommendations = []
				querySnapshot.forEach(doc => {
					this.recommendations.push(doc.data())
				})
			})
		},
		components: {
			'beat-loader': BeatLoader,
		},
		methods: {
			itemInCart(recommendation){
				let itemQty = Object.keys(this.cart.filter(item => item.id == recommendation.id)).length
				if(itemQty > 0) return true
				else return false
			},
			addRecommendationToCart(recommendation){
				if(this.itemInCart(recommendation)) {
					return
				}
				recommendation['qty'] = 1
				this.cart.push(recommendation)
				this.$toasted.show("Done! Added To Cart<i class='icon-ok-circle text-success'></i>")
			}						
		}
	}	
</script>